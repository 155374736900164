var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"tabela",staticClass:"table-ticket pa-4 articulat-medium",attrs:{"footer-props":{ itemsPerPageOptions: [-1] },"headers":_vm.headersFiltered,"hide-default-footer":true,"items":_vm.items,"loading":_vm.loading,"dense":"","fixed-header":"","height":"calc(90vh - 200px)","item-key":_vm.propItemKey || 'id',"show-expand":_vm.propExpandable,"single-expand":_vm.propExpandable},on:{"item-expanded":_vm.funcExpandable},scopedSlots:_vm._u([(_vm.propPermiteImpimirTabela)?{key:"top",fn:function(){return [_c('imprimir-tabela-component',{staticStyle:{"flex-grow":"inherit"},attrs:{"propRef":[_vm.tabelaRef]}})]},proxy:true}:null,{key:"footer",fn:function(){return [_c('v-row',{staticClass:"mt-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"primary",attrs:{"small":"","loading":_vm.loading},on:{"click":function($event){return _vm.doSearchItems()}}},[_vm._v(" carregar mais "+_vm._s(_vm.propCustomLimit ? _vm.propCustomLimit : "100")+" ")])],1),_c('v-col',{staticClass:"pl-1"},[_vm._v(" Exibindo "+_vm._s(_vm.items.length)+" registro(s) ")])],1)]},proxy:true},_vm._l((_vm.$scopedSlots),function(x,slotName){return {key:slotName,fn:function(context){return [_vm._t(slotName,null,null,context)]}}}),{key:"body.prepend",fn:function(ref){
var headers = ref.headers;
return [(
        headers.some(function (header) { return ['none', 'str', 'cpf', 'int'].includes(header.type); }
        )
      )?_c('tr',{staticStyle:{"background-color":"#ffffff"},attrs:{"id":"tr-filter"}},_vm._l((headers),function(item,idx){return _c('td',{key:idx},[(item.type == 'none')?_c('div'):(item.type == 'str')?_c('div',[_c('v-text-field',{staticClass:"pt-2 pb-2",attrs:{"name":"new","autocomplete":"off","type":"text","label":item.text,"id":item.value,"hide-details":"auto","solo":""},model:{value:(_vm.filtros[item.value]),callback:function ($$v) {_vm.$set(_vm.filtros, item.value, $$v)},expression:"filtros[item.value]"}})],1):(item.type == 'cpf')?_c('div',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],staticClass:"pt-2 pb-2",attrs:{"name":"new","autocomplete":"off","type":"text","label":"CPF","id":item.value,"hide-details":"auto","solo":""},model:{value:(_vm.filtros[item.value]),callback:function ($$v) {_vm.$set(_vm.filtros, item.value, $$v)},expression:"filtros[item.value]"}})],1):(item.type == 'int')?_c('div',[_c('v-text-field',{staticClass:"pt-2 pb-2",attrs:{"name":"new","autocomplete":"off","type":"number","label":item.text,"id":item.value,"hide-details":"auto","solo":""},model:{value:(_vm.filtros[item.value]),callback:function ($$v) {_vm.$set(_vm.filtros, item.value, $$v)},expression:"filtros[item.value]"}})],1):_vm._e()])}),0):_vm._e()]}},{key:"body.append",fn:function(ref){
      var headers = ref.headers;
return [(_vm.propSum)?_c('tr',{staticStyle:{"position":"sticky","bottom":"0","background-color":"white"}},_vm._l((headers),function(h,idx){return _c('td',{key:idx},[(idx === 0)?_c('b',[_vm._v("Total")]):_vm._e(),(h.value == 'value' || h.value.toLowerCase().includes('valor'))?_c('b',[_c('b',[_vm._v(" "+_vm._s(_vm.sumField(_vm.items, h.value))+" ")])]):_vm._e()])}),0):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }