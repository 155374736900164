import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import VDataTableCheckbox from '@/components/VDataTableCheckbox.vue';
import _ from 'lodash';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
import { analyticsStore, filtroPorTabelaStore } from '@/utils/store-accessor';
import FiltroPorTabela from '@/components/FiltrosPorTabela.vue';
let GenericListItemsCardComponent = class GenericListItemsCardComponent extends Vue {
    constructor() {
        super(...arguments);
        this.tabelaRef = null;
        this.filtros = {};
        this.timer = 0;
        this.showTable = true;
        this.loadingRefresh = false;
    }
    get loading() {
        return analyticsStore.loading;
    }
    headers() {
        return analyticsStore.headers;
    }
    get items() {
        return analyticsStore.items;
    }
    get skip() {
        return analyticsStore.skip;
    }
    get limit() {
        return analyticsStore.limit;
    }
    get headersFiltered() {
        return _.filter(this.headers(), item => item.show);
    }
    async watchSearch(val) {
        clearTimeout(this.timer);
        // @ts-ignore
        this.timer = setTimeout(async () => {
            analyticsStore.setFiltros(this.filtros);
            analyticsStore.executeSearchItems();
        }, 800);
    }
    async doSearchItems(initialize = false) {
        analyticsStore.executeSearchItems(initialize);
    }
    showFiltroPorTabela() {
        const form = this.$refs.filtroPorTabela;
        form.show();
    }
    async carregaFiltroPorTabela() {
        if (this.loadingRefresh) {
            return;
        }
        this.loadingRefresh = true;
        const items = await filtroPorTabelaStore.filtroPorTabelaGet(this.propTableName);
        this.loadingRefresh = false;
    }
    async mounted() {
        this.tabelaRef = this.$refs.tabela;
        if (this.propTableName) {
            await filtroPorTabelaStore.filtroPorTabelaBuscaFiltroSessao(this.propTableName);
        }
        if (this.propCustomSort) {
            // @ts-ignore
            this.$refs.tabela._props.customSort = this.customSort;
        }
        analyticsStore.setLimit(this.propCustomLimit ? this.propCustomLimit : 100);
    }
};
__decorate([
    Prop({ type: String, default: '' })
], GenericListItemsCardComponent.prototype, "propFiltroPorTabela", void 0);
__decorate([
    Prop({ type: String, default: null })
], GenericListItemsCardComponent.prototype, "propTableName", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], GenericListItemsCardComponent.prototype, "propExpandable", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], GenericListItemsCardComponent.prototype, "propSum", void 0);
__decorate([
    Prop({
        type: Function,
        required: false,
        default: () => {
            return;
        }
    })
], GenericListItemsCardComponent.prototype, "funcExpandable", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], GenericListItemsCardComponent.prototype, "propCustomSort", void 0);
__decorate([
    Prop({ type: Function })
], GenericListItemsCardComponent.prototype, "customSort", void 0);
__decorate([
    Prop({ type: String, required: false })
], GenericListItemsCardComponent.prototype, "propItemKey", void 0);
__decorate([
    Prop({ type: Number, required: false })
], GenericListItemsCardComponent.prototype, "propCustomLimit", void 0);
__decorate([
    Prop({ type: Boolean, required: false })
], GenericListItemsCardComponent.prototype, "propPermiteImpimirTabela", void 0);
__decorate([
    Watch('filtros', { deep: true })
], GenericListItemsCardComponent.prototype, "watchSearch", null);
GenericListItemsCardComponent = __decorate([
    Component({
        components: {
            VDataTableCheckbox,
            ImprimirTabelaComponent,
            FiltroPorTabela
        }
    })
], GenericListItemsCardComponent);
export default GenericListItemsCardComponent;
