import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, prospexStore, mainStore, analyticsStore, ocorrenciaStore, filtroPorTabelaStore, } from '@/store';
import OcorrenciaComponent from '@/components/OcorrenciaComponent.vue';
import CriarOcorrenciaComponent from '@/components/CriarOcorrenciaComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardUpdatedComponent from '@/components/GenericListItemsCardUpdatedComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import SaveButton from '@/components/SaveButton.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
import CustomTabsComercialComponent from '@/components/CustomTabsComercialComponent.vue';
let ProspexList = class ProspexList extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Nome',
                table: 'prospex',
                value: 'nome',
                type: 'str',
                show: true,
            },
            {
                text: 'Registrar atendimento',
                table: 'prospex',
                value: 'idade',
                type: '',
                show: true,
            },
            {
                text: 'Possivel curso',
                table: 'tmp',
                value: 'curso_possivel',
                type: '',
                show: true,
            },
            {
                text: 'Dias de preferência',
                table: 'prospex',
                value: 'dias_preferencia',
                type: '',
                show: true,
            },
            {
                text: 'Período de preferência',
                value: 'periodo_preferencia',
                table: 'prospex',
                type: '',
                show: true,
            },
            {
                text: 'Celular',
                value: 'celular',
                table: 'prospex',
                type: 'str',
                show: true,
            },
            {
                table: 'primeiro_atendimento_usuario',
                text: 'Primeiro atendimento',
                type: 'str',
                show: true,
                value: 'primeiro_atendimento_full_name',
                field: 'full_name',
            },
            {
                text: 'Data do primeiro atendimento',
                value: 'created_at',
                table: 'prospex',
                type: '',
                show: true,
            },
            {
                table: 'segundo_atendimento_usuario',
                text: 'Segundo atendimento',
                type: 'str',
                show: true,
                value: 'segundo_atendimento_full_name',
                field: 'full_name',
            },
            {
                text: 'Status',
                value: 'status_id',
                table: 'prospex',
                type: '',
                show: true,
            },
            {
                text: 'Motivo de perda',
                value: 'motivo_perda_id',
                table: 'prospex',
                type: '',
                show: true,
            },
            {
                text: 'Visita de',
                value: 'parceria_cupom_id',
                table: 'prospex',
                type: '',
                show: true,
            },
        ];
        this.ocorrenciasHeaders = [
            {
                text: 'Data',
                value: 'created_at',
                table: 'ocorrencia',
                show: true,
                type: 'date',
            },
            {
                text: 'Criado por',
                table: 'ocorrencia',
                value: 'created_by_id',
                show: true,
            },
            {
                text: 'Departamento',
                table: 'ocorrencia',
                value: 'departamento_id',
                show: true,
            },
            {
                text: 'Contato',
                table: 'objecao',
                value: 'objecao_nome',
                field: 'nome',
                show: true,
            },
            {
                text: 'Assunto',
                table: 'objecao',
                value: 'assunto',
                show: true,
            },
            {
                text: 'Observação',
                table: 'ocorrencia',
                value: 'observacao',
                type: 'str',
                show: true,
            },
        ];
        this.dialogCriarOcorrencia = false;
        this.entidadeIdOcorrenciaAtual = null;
        this.entidadeTipoOcorrenciaAtual = '';
        this.ocorrenciasItems = [];
        this.loadingOcorrencias = false;
        this.carregandoTotals = false;
        this.selectPeriodDate = [];
        this.loadTotals = false;
        this.selectedTab = 8;
        this.menu = false;
        this.dialogFiltrosAdicionais = false;
        this.selectCheckbox = null;
        this.loadingSave = false;
        this.tabelaRef = null;
        this.totalsCarregados = false;
        this.loading = false;
    }
    get totals() {
        return analyticsStore.totals;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Prospex) {
            return userToolStore.userToolsIndexed.Prospex[0].allow_create;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Prospex) {
            return userToolStore.userToolsIndexed.Prospex[0].allow_delete;
        }
    }
    get formattedDate() {
        if (this.selectPeriodDate.length === 2) {
            const [start, end] = this.selectPeriodDate;
            return `${this.formatDate(start)} - ${this.formatDate(end)}`;
        }
        else if (this.selectPeriodDate.length === 1) {
            return `${this.formatDate(this.selectPeriodDate[0])}`;
        }
        return '';
    }
    aplicarFiltros() {
        this.dialogFiltrosAdicionais = false;
    }
    async onChangeSelectAllCheckbox(val) {
        if (this.loadingSave) {
            return;
        }
        this.loadingSave = true;
        await filtroPorTabelaStore.filtroPorTabelaBuscaFiltroSessao('list_prospex');
        this.headers.forEach((header) => {
            header.show = val.includes(header.value);
        });
        const items = this.headers.filter((item) => item.show);
        await filtroPorTabelaStore.filtroPorTabelaCreate({
            items,
            table_name: 'list_prospex',
        });
        this.loadingSave = false;
    }
    async created() {
        this.$nextTick(() => {
            this.tabelaRef = this.$refs.tabela;
        });
    }
    async onDateChange() {
        if (this.selectPeriodDate.length === 2) {
            this.buscar();
        }
    }
    async selecionarTudo() {
        for (const key in this.totals) {
            if (this.totals[key].items) {
                this.totals[key].items.forEach((item) => {
                    item.selected = true;
                });
            }
        }
    }
    async limparSelecionados() {
        for (const key in this.totals) {
            if (this.totals[key].items) {
                this.totals[key].items.forEach((item) => {
                    item.selected = false;
                });
            }
        }
    }
    async buscarTotals() {
        if (this.totalsCarregados) {
            return;
        }
        this.carregandoTotals = true;
        await this.doSearchTotals();
        this.carregandoTotals = false;
        this.loadTotals = true;
        this.totalsCarregados = true;
    }
    async onCancel() {
        this.dialogCriarOcorrencia = false;
    }
    async showOcorrencias(item) {
        if (item.value == true) {
            this.loadingOcorrencias = true;
            this.ocorrenciasItems = await ocorrenciaStore.getMultiOcorrencias({
                types: ['prospex'],
                entidade_id: item.item.id,
            });
            this.entidadeIdOcorrenciaAtual = item.item.id;
            this.entidadeTipoOcorrenciaAtual = 'prospex';
            this.loadingOcorrencias = false;
        }
    }
    motivoPerdaOptions() {
        return [
            { id: 1, name: 'Motivo 1' },
            { id: 2, name: 'Motivo 2' },
        ];
    }
    async buscar() {
        analyticsStore.setDefaultFilters({
            'prospex.created_at': {
                type: 'timestamp',
                value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
            },
        });
        // ficava aqui quando carregava na abertura da tela
        if (this.loadTotals) {
            await this.doSearchTotals();
        }
        await analyticsStore.executeSearchItems();
        // @ts-ignore
    }
    async doSearchItems(queryIn) {
        return await prospexStore.getProspexs(queryIn);
    }
    async doSearchTotals() {
        if (this.selectPeriodDate && this.selectPeriodDate.length === 2) {
            const [data_inicial, data_final] = this.selectPeriodDate;
            if (data_inicial && data_final) {
                return await analyticsStore.getAnalyticsProspex({
                    data_inicial,
                    data_final,
                });
            }
        }
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(prospexStore.getProspexs);
        analyticsStore.setHeaders(this.headers);
        this.selectCheckbox = this.headers.map((item) => item.value);
        // tela que nao tem defaultFilter precisa ter o buscar() aqui
        // this.buscar();
        const today = new Date();
        const last30Days = new Date();
        last30Days.setDate(today.getDate() - 30);
        this.selectPeriodDate = [
            last30Days.toISOString().substr(0, 10),
            today.toISOString().substr(0, 10),
        ];
        this.buscar();
    }
    navigateToEdit(id) {
        this.$router.push({ name: 'main-prospexs-edit', params: { id } });
    }
};
ProspexList = __decorate([
    Component({
        components: {
            ClickableCard,
            OcorrenciaComponent,
            OpenNewTabComponent,
            GenericListTotalCardComponent,
            GenericListItemsCardUpdatedComponent,
            TextExpandableComponent,
            CriarOcorrenciaComponent,
            PeriodSelectorComponent,
            SaveButton,
            ButtonComponent,
            ImprimirTabelaComponent,
            CustomTabsComercialComponent,
        },
    })
], ProspexList);
export default ProspexList;
